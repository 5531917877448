/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
@import "./fonts/font.css";

/** Ionic CSS Variables **/
:root {
	/* Custom variables */
	--font-family-primary: "Circular Std", Helvetica, "Helvetica Neue", Aria,
		sans-serif;
	--font-family-secondary: var(--font-family-primary);
	--font-weight-normal: 300;
	--font-weight-bold: 700;
	--font-weight-bolder: 900;
	--letter-spacing: -0.02em;
	--letter-spacing-secondary: 0.02em;
	--text-font-size-4xs: 9px;
	--text-font-size-3xs: 11px;
	--text-font-size-2xs: 13px;
	--text-font-size-xs: 15px;
	--text-font-size-sm: 17px;
	--text-font-size: 19px;
	--text-font-size-lg: 21px;
	--text-font-size-xl: 23px;
	--text-font-size-2xl: 25px;
	--text-font-size-3xl: 29px;
	--text-font-size-4xl: 33px;
	--line-height: 1.421;
	--line-height-secondary: 1.5;
	--strong-text-font-size: var(--text-font-size);
	--icon-font-size-small: 16px;
	--icon-font-size-medium: 24px;
	--icon-font-size-large: 32px;
	/*
		Application variables
	*/
	--ion-font-family: var(--font-family-primary);

	/*
		Application colors
	*/
	--ion-background-color: #ffffff;
	--ion-background-color-rgb: 255, 255, 255;
	--ion-text-color: #191919;
	--ion-text-color-rgb: 25, 25, 25;
	--ion-border-color: var(--ion-color-step-100);
	--ion-tab-bar-background: var(--ion-color-dark);
	--ion-tab-bar-background-focused: var(--ion-color-dark-tint);
	--ion-tab-bar-border-color: var(--ion-color-dark);
	--ion-tab-bar-color: rgba(var(--ion-color-dark-contrast-rgb), 0.5);
	--ion-tab-bar-color-selected: var(--ion-color-dark-contrast);
	--ion-toolbar-background: var(--ion-background-color);
	--ion-toolbar-border-color: var(--ion-background-color);
	--ion-toolbar-color: var(--ion-text-color);
	--ion-placeholder-color: rgba(var(--ion-color-dark-rgb), 0.5);

	/* 
		Stepped colors
	*/
	--ion-color-step-50: #f4f4f4;
	--ion-color-step-100: #e8e8e8;
	--ion-color-step-150: #dddddd;
	--ion-color-step-200: #d1d1d1;
	--ion-color-step-250: #c6c6c6;
	--ion-color-step-300: #bababa;
	--ion-color-step-350: #afafaf;
	--ion-color-step-400: #a3a3a3;
	--ion-color-step-450: #989898;
	--ion-color-step-500: #8c8c8c;
	--ion-color-step-550: #818181;
	--ion-color-step-600: #757575;
	--ion-color-step-650: #6a6a6a;
	--ion-color-step-700: #5e5e5e;
	--ion-color-step-750: #535353;
	--ion-color-step-800: #474747;
	--ion-color-step-850: #3c3c3c;
	--ion-color-step-900: #303030;
	--ion-color-step-950: #252525;

	/* 
		Theme colors
	*/
	--ion-color-primary: #191919;
	--ion-color-primary-rgb: 25, 25, 25;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #161616;
	--ion-color-primary-tint: #303030;

	--ion-color-secondary: #3dc2ff;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #36abe0;
	--ion-color-secondary-tint: #50c8ff;

	--ion-color-tertiary: #6a64ff;
	--ion-color-tertiary-rgb: 106, 100, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #5d58e0;
	--ion-color-tertiary-tint: #7974ff;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #191919;
	--ion-color-warning-contrast-rgb: 25, 25, 25;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-dark: #191919;
	--ion-color-dark-rgb: 25, 25, 25;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #161616;
	--ion-color-dark-tint: #303030;

	--ion-color-medium: #bababa;
	--ion-color-medium-rgb: 186, 186, 186;
	--ion-color-medium-contrast: #191919;
	--ion-color-medium-contrast-rgb: 25, 25, 25;
	--ion-color-medium-shade: #a4a4a4;
	--ion-color-medium-tint: #c1c1c1;

	--ion-color-light: #ffffff;
	--ion-color-light-rgb: 255, 255, 255;
	--ion-color-light-contrast: #191919;
	--ion-color-light-contrast-rgb: 25, 25, 25;
	--ion-color-light-shade: #ffffff;
	--ion-color-light-tint: #ffffff;
}
