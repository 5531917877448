.preview {
	display: flex;
	align-items: center;
	width: 100%;
	min-height: 72px;
	border-radius: 0px;
	padding: 8px 0;
}

.thumbnail {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 72px;
	width: 72px;
	flex-grow: 0;
	flex-shrink: 0;
	border: 5px solid var(--ion-color-dark);
	border-radius: 0px;
	font-size: 45px;
	font-weight: var(--font-weight-bold);
}

.body {
	flex-shrink: 1;
	flex-grow: 1;
	min-width: 0;
	padding-left: 12px;
}

.title {
	font-size: var(--text-font-size);
	font-weight: var(--font-weight-bold);
	line-height: var(--line-height);
	text-align: left;
	margin: 0;
}

.meta {
	display: flex;
	align-items: flex-start;
	margin-top: 8px;
}

.duration {
	align-items: center;
	display: inline-flex;
	margin: 0;
}

.durationText {
	display: block;
	font-size: var(--text-font-size-2xs);
	font-weight: var(--font-weight-normal);
	letter-spacing: var(--letter-spacing);
	line-height: 16px;
	margin-left: 6px;
}

.playIcon {
	flex-shrink: 0;
}
