.button,
.icon {
	/* display block fixes line-height issues */
	display: block;
}

.button {
	appearance: none;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	margin: 0;
	padding: 0;
}

.icon {
	height: 1.5rem;
	width: 1.5rem;
}

.icon.isHidden {
	display: none;
}
