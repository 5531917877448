.list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.item::before {
  display: block;
	width: calc(100vw - 24px);
	height: 2px;
	float: right;
	background-color: var(--ion-color-dark);
	content: '';
}