.footer.topEdgeFade::after {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 24px;
	background: linear-gradient(
		to top,
		rgba(255, 255, 255, 1) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	transform: translateY(-100%);
	content: "";
}

.credits {
	font-size: var(--text-font-size-2xs);
	text-align: center;
	margin: 0;
}

.looolLogo {
	width: auto;
	height: 12px;
}
