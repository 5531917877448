@font-face {
    font-family: 'Circular Std';
    src: url('font/hinted-subset-CircularStd-Black.eot');
    src: url('font/hinted-subset-CircularStd-Black.eot?#iefix') format('embedded-opentype'),
        url('font/hinted-subset-CircularStd-Black.woff2') format('woff2'),
        url('font/hinted-subset-CircularStd-Black.woff') format('woff'),
        url('font/hinted-subset-CircularStd-Black.ttf') format('truetype'),
        url('font/hinted-subset-CircularStd-Black.svg#CircularStd-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Circular Std';
    src: url('font/hinted-subset-CircularStd-Bold.eot');
    src: url('font/hinted-subset-CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('font/hinted-subset-CircularStd-Bold.woff2') format('woff2'),
        url('font/hinted-subset-CircularStd-Bold.woff') format('woff'),
        url('font/hinted-subset-CircularStd-Bold.ttf') format('truetype'),
        url('font/hinted-subset-CircularStd-Bold.svg#CircularStd-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Circular Std';
    src: url('font/hinted-subset-CircularStd-Light.eot');
    src: url('font/hinted-subset-CircularStd-Light.eot?#iefix') format('embedded-opentype'),
        url('font/hinted-subset-CircularStd-Light.woff2') format('woff2'),
        url('font/hinted-subset-CircularStd-Light.woff') format('woff'),
        url('font/hinted-subset-CircularStd-Light.ttf') format('truetype'),
        url('font/hinted-subset-CircularStd-Light.svg#CircularStd-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Circular Std';
    src: url('font/hinted-subset-CircularStd-BoldItalic.eot');
    src: url('font/hinted-subset-CircularStd-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('font/hinted-subset-CircularStd-BoldItalic.woff2') format('woff2'),
        url('font/hinted-subset-CircularStd-BoldItalic.woff') format('woff'),
        url('font/hinted-subset-CircularStd-BoldItalic.ttf') format('truetype'),
        url('font/hinted-subset-CircularStd-BoldItalic.svg#CircularStd-BoldItalic') format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Circular Std';
    src: url('font/hinted-subset-CircularStd-LightItalic.eot');
    src: url('font/hinted-subset-CircularStd-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('font/hinted-subset-CircularStd-LightItalic.woff2') format('woff2'),
        url('font/hinted-subset-CircularStd-LightItalic.woff') format('woff'),
        url('font/hinted-subset-CircularStd-LightItalic.ttf') format('truetype'),
        url('font/hinted-subset-CircularStd-LightItalic.svg#CircularStd-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Inlove';
    src: url('font/hinted-subset-Inlove-Bold.eot');
    src: url('font/hinted-subset-Inlove-Bold.eot?#iefix') format('embedded-opentype'),
        url('font/hinted-subset-Inlove-Bold.woff2') format('woff2'),
        url('font/hinted-subset-Inlove-Bold.woff') format('woff'),
        url('font/hinted-subset-Inlove-Bold.ttf') format('truetype'),
        url('font/hinted-subset-Inlove-Bold.svg#Inlove-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

