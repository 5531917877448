body {
	font-size: var(--text-font-size);
	font-weight: var(--font-weight-normal);
	letter-spacing: var(--letter-spacing);
}

h1,
h2,
h3,
h4,
h5,
h6,
ion-title {
	margin-top: 0;
	font-weight: var(--font-weight-bold);
	letter-spacing: var(--letter-secondary);
	font-family: var(--font-family-secondary);
}

p {
	margin-top: 0;
	line-height: var(--line-height);
}

strong,
b {
	font-size: var(--strong-text-font-size);
	font-weight: var(--font-weight-bold);
}

ion-header ion-toolbar::after {
	display: block;
	width: calc(100% - 24px);
	height: 5px;
	float: right;
	background-color: var(--ion-color-dark);
	content: "";
}

.footer-ios ion-toolbar:first-of-type {
	--border-width: 0;
}

.footer-ios ion-toolbar:first-of-type::before {
	display: block;
	width: calc(100% - 24px);
	height: 5px;
	float: right;
	background-color: var(--border-color);
	content: "";
}

ion-toolbar {
	/* make sure header has same height in all case */
	/* by resetting top,bottom padding and adding min-height */
	--padding-top: 0px;
	--padding-bottom: 0px;
	--padding-start: 24px;
	--padding-end: 24px;
	--min-height: 64px;
}

ion-title[slot="start"] {
	position: static;
	padding-inline-start: 0;
	padding-inline-end: 0;
	text-align: left;
	font-weight: var(--font-weight-bold);
	height: 64px;
}

* + ion-title[slot="start"] {
	margin-left: 12px;
}

ion-title.title-default {
	font-size: 23px;
}

ion-content {
	--padding-top: 32px;
	--padding-bottom: 32px;
	--padding-start: 24px;
	--padding-end: 24px;
}

ion-back-button {
	--icon-font-size: var(--icon-font-size-medium);
	--icon-margin-start: 0px;
	--icon-margin-end: 0px;
	--icon-padding-start: 0px;
	--icon-padding-end: 0px;
}

button,
ion-button {
	letter-spacing: var(--letter-spacing);
	font-weight: var(--font-weight-bold);
	margin: 0px;
	font-family: var(--font-family-secondary);
}

button {
	transition: border-color 400ms linear, background-color 400ms linear,
		color 400ms linear;
}

ion-button {
	--transition: border-color 400ms linear, background-color 400ms linear,
		color 400ms linear;
}

ion-button {
	--border-width: 5px;
	font-size: var(--text-font-size);
}

ion-button:not(.button-round) {
	--border-radius: 0px;
}

ion-button.button-outline {
	--border-color: var(--ion-color-dark);
}

ion-button.button-outline.ion-activated {
	--border-color: var(--ion-color-dark);
}

ion-button.button-small {
	height: 32px;
	--padding-start: calc(12px - var(--border-width));
	--padding-end: calc(12px - var(--border-width));
	font-size: 17px;
}

ion-tab-bar {
	height: 64px;
}

.sc-ion-buttons-ios-s ion-button {
	--padding-start: 0px;
	--padding-end: 0px;
	margin-left: 0px;
	margin-right: 0px;
	height: 24px;
}

.sc-ion-buttons-ios-s ion-icon[slot="icon-only"] {
	font-size: var(--icon-font-size-medium);
}

ion-tab-button ion-icon {
	font-size: var(--icon-font-size-medium);
	transition: 0.4s;
}

ion-icon.is-small {
	font-size: var(--icon-font-size-small);
}

ion-icon.is-medium {
	font-size: var(--icon-font-size-medium);
}

ion-icon.is-large {
	font-size: var(--icon-font-size-large);
}

ion-button.button-solid {
	--transition: background-color, opacity, box-shadow 100ms linear;
}

/* 
	Hide the vertical scrollbar on the scrollable element
	in the ion-content by pushing it outside of the view.

	For a some reason the ::-webkit-scrollbar does not works
	on the ion-content::part(scroll) 🤔	
*/
ion-content::part(scroll) {
	width: calc(100% + 16px);
	padding-right: calc(var(--padding-end) + 16px);
}

.searchbar-input.sc-ion-searchbar-ios {
	font-size: var(--text-font-size);
}
