.wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
}

.content::part(scroll) {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-height: 100%;
	text-align: center;
}

.subtitle {
	font-size: var(--text-font-size-lg);
	margin-bottom: 8px;
}

.message {
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 32px;
}

.restartButtonWrapper {
	text-align: center;
}
