.content {
	text-align: center;
}

.content::part(scroll) {

	padding-bottom: 0;
	padding-top: 0;
}

.innerContainer {
	display: flex;
  flex-wrap: wrap;
  align-items: stretch;
	justify-content: center;
  height: 100%;
}

.title,
.message {
	margin-bottom: 0px;
}

.title {
	font-size: 69px;
	font-weight: var(--font-weight-bolder);
	line-height: 69px;
	margin-bottom: 16px;
}

.message span {
	display: block;
	font-weight: var(--font-weight-bold);
}

.welcomeMessageBlock {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.bottomBlock {
	margin: 0 -24px;
	width: 100vw;
	background-color: var(--ion-color-dark);
	color: var(--ion-color-dark-contrast);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.errorMessage {
	margin-bottom: 16px;
}

.ctaButton {
	width: 100%;
	height: 100%;
	font-size: var(--text-font-size-3xl);
	font-weight: var(--font-weight-bold);
}

.ctaButtonIcon {
	margin-inline-start: 12px;
}

.bottomToolbar {
	--border-width: 0px;
}

.bottomToolbar::before {
	height: 2px;
	width: calc(100% - 24px);
	float: right;
	background-color: var(--ion-color-light);
	content: '';
}

.bottomToolbar small {
	font-size: var(--text-font-size-2xs);
}

.footerLooolText {
	font-family: 'Inlove', var(--font-family-primary);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.1em;
}

.footerTrademarkSymbol {
	position: relative;
	top: -3px;
	font-size: var(--text-font-size-4xs);
	font-weight: var(--font-weight-bold);
}