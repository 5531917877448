.list {
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	margin-top: -8px;
	margin-bottom: -8px;
}

.stopLink {
	display: block;
	color: inherit;
	text-decoration: none;
}

.title {
	transition: 0.4s;
	transition-delay: 0.02s;
}

.title.isHidden {
	transform: translate3d(-24px, 0, 0);
	opacity: 0;
	visibility: hidden;
}
