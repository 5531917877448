.playPauseWrapper {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 144px 0px;
	width: 100%;
	opacity: 1;
	text-align: center;
	transition: opacity 0.5s;
}

.playPauseWrapper.fadeout {
	transition: opacity 0.5s;
	opacity: 0;
}

.playIcon.isHidden,
.pauseIcon.isHidden {
	display: none;
}
