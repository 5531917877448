.progressPercentage {
	display: block;
	text-align: center;
	font-size: var(--text-font-size-2xs);
	font-weight: var(--font-weight-bold);
	letter-spacing: var(--letter-spacing-secondary);
}

.progressBar {
	--background: var(--ion-color-step-200);
	--progress-background: var(--ion-color-primary);
	height: 8px;
	border-radius: 8px;
}

.content::part(scroll) {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.errorMessage {
	text-align: center;
	white-space: pre-line;
}
