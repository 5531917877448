.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-size: var(--text-font-size);
  font-weight: var(--font-weight-bold);
}

.buttons {
  display: flex;
  align-items: center;
  gap: 24px;
}

.stars {
  display: inline-flex;
  align-items: center;
  gap: 24px;
}

.star {
  position: relative;
  height: 24px;
  width: 24px;
  padding: 0;
  border: 0; 
  background-color: transparent;
  appearance: none;
  transition: opacity 400ms;
}

.star ion-icon {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 400ms;
}

.star ion-icon:last-child {
  opacity: 0;
  transform: scale(0.8);
}

.star.isActive ion-icon:last-child {
  opacity: 1;
}

.button {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
  --transition: max-width 300ms linear, border-color 400ms linear, background-color 200ms linear, color 400ms linear;
  position: relative;
  margin-left: auto;
}

.button.isDisabled {
  --background: transparent;
  --color: var(--ion-color-primary);
  opacity: 1;
}

.button::part(native) {
  max-width: 96px;
}

.button ion-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--ion-color-primary-contrast);
  opacity: 0;
}

.container.isRated .star:not(.isActive) {
  opacity: 0;
}

.container.isRated .button::part(native) {
  max-width: 32px;
  opacity: 1 !important;
}

.container.isRated .button span {
  visibility: hidden;
}

.container.isRated .button ion-icon {
  opacity: 1;
  transition-property: opacity;
  transition-duration: 400ms;
}
