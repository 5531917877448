.topToolbar {
	--background: transparent;
	--border-color: transparent;
	--border-width: 0px;
	--color: var(--ion-color-light);
	--ion-toolbar-color: var(--ion-color-light);
}

.content {
	--padding-start: 0px;
	--padding-end: 0px;
	--padding-top: 0px;
	--padding-bottom: 0px;
}

.content::part(scroll) {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.content::part(background) {
	background-size: cover;
}
/* todo firefox backdrop-filter fallback */
.content.hasVideo::part(scroll) {
	backdrop-filter: blur(25px);
	backface-visibility: hidden;
}

.mediaPlayerControls {
	display: grid;
	grid-template-columns: auto 1fr auto;
	gap: 11px;
	align-items: center;
}

.playHeadPositionWrapper {
	width: 40px;
	white-space: nowrap;
}

.bottomSheet [data-rsbs-backdrop],
.bottomSheet [data-rsbs-overlay],
.bottomSheet[data-rsbs-root]:after {
	z-index: 3;
	-ms-scroll-chaining: none;
	overscroll-behavior: none;
	touch-action: none;
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

.bottomSheet[data-rsbs-root]:after {
	content: "";
	pointer-events: none;
	background: #191919;
	background: var(--rsbs-bg, #191919);
	height: 1px;
	transform-origin: bottom;
	transform: scaleY(0);
	transform: scaleY(var(--rsbs-antigap-scale-y, 0));
	will-change: transform;
}

.bottomSheet [data-rsbs-overlay] {
	order-top-left-radius: 16px;
	display: flex;
	background: #191919;
	background: var(--rsbs-bg, #191919);
	color: var(--ion-color-dark-contrast);
	flex-direction: column;
	height: 0;
	height: var(--rsbs-overlay-h, 0);
	transform: translateZ(0);
	transform: translate3d(0, var(--rsbs-overlay-translate-y, 0), 0);
	will-change: height;
	max-height: calc(100% - 64px);
}

.bottomSheet [data-rsbs-scroll] {
	overflow-y: scroll;
}

.bottomSheet [data-rsbs-scroll]::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.bottomSheet [data-rsbs-scroll] {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.bottomSheetHeaderContent {
	display: flex;
	align-items: center;
	gap: 16px;
	min-width: 0px;
	height: 64px;
	padding-left: 24px;
	padding-right: 24px;
}

.closeBottomSheetButton {
	--padding-start: 0px;
	--padding-end: 0px;
	width: auto;
	height: auto;
	margin-left: auto;
	transform: rotate(180deg);
}

.bottomSheetScrollContent {
	position: relative;
	padding-top: 0;
	padding-bottom: 69px;
	padding-left: 24px;
	padding-right: 24px;
}

.bottomSheetScrollContent::after {
	position: sticky;
	bottom: 69px;
	display: block;
	height: 24px;
	width: 100%;
	background: linear-gradient(
		to bottom,
		rgba(var(--ion-color-dark-rgb), 0),
		rgba(var(--ion-color-dark-rgb), 1)
	);
	content: "";
}

.bottomSheetScrollContent.hasNoContent::after {
	display: none;
}

.title {
	display: block;
	/* Fix infinite scroll bug when parent has display: flex */
	min-width: 0;
	margin: 0;
	font-size: var(--text-font-size-xl);
	font-weight: var(--font-weight-bold);
	line-height: 1;
}

.description {
	margin: 0;
	padding: 0;
	line-height: var(--line-height);
}

.likeButton {
	position: relative;
}

/* Layer icon to create fill effect for the outline icon */
/* on buttons "liked" state changes */
.likedIcon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0);
	transition: 0.2s;
	opacity: 0.25;
}

/* Fill the outlined icon */
.likeButton.isLiked .likedIcon {
	transform: translate(-50%, -50%) scale(1);
	opacity: 1;
}

.openBottomSheetButton {
	--background: var(--ion-color-dark);
	order: -1;
	position: absolute;
	bottom: 69px;
	left: 0;
	right: 0;
	z-index: 5;
	height: 64px;
	transition: 400ms;
	font-size: var(--text-font-size-xl);
}

.openBottomSheetButton.isHidden {
	transform: translate3d(0, 100%, 0);
}

.openBottomSheetButton.isHiddenOutScreen {
	transform: translate3d(0, calc(100% + 69px), 0);
}

.openBottomSheetButton > span {
	display: inline-block;
	min-width: 0;
	margin-right: auto;
}

.openBottomSheetButton ion-icon {
	margin-right: 0;
	flex-shrink: 0;
}

.footer {
	position: absolute;
	z-index: 50;
	bottom: 0;
	display: flex;
	flex-direction: column;
	transition: 400ms;
	transform: translate3d(0, 0, 0);
}

.footer.isHiddenOutScreen {
	transform: translate3d(0, 100%, 0);
}

.toolbarBottom {
	--background: var(--ion-color-dark);
	--color: var(--ion-color-dark-contrast);
	--border-color: var(--ion-color-dark-contrast);
}
