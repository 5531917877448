.wrapper {
	position: relative;
	height: 100%;
}

.innerWrapper {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	text-align: center;
	left: 0;
	right: 0;
}

.subtitle {
	font-size: var(--text-font-size-lg);
	margin-bottom: 8px;
}

.text {
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
}
