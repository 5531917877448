.links {
	margin-top: 24px;
}

.links::before {
	display: block;
	height: 5px;
	width: calc(100% + 24px);
	margin-bottom: 12px;
	margin-right: -24px;
	background-color: var(--ion-color-dark);
	content: '';
}

.createdBy {
	display: flex;
	align-items: center;
	font-size: var(--text-font-size);
	font-weight: var(--font-weight-normal);
	letter-spacing: var(--letter-spacing);
	text-decoration: none;
}

.createdByLogo {
	margin-right: 12px;
}

.createdByIcon {
	margin-left: auto;
}

.createdByLooolText {
	font-family: 'Inlove', var(--font-family-primary);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.1em;
}

.createdByTrademarkSymbol {
	position: relative;
	top: -7px;
	font-size: var(--text-font-size-3xs);
	font-weight: var(--font-weight-bold);
}
